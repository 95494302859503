import React, { useEffect } from "react";
import { handleCallback } from "../services/auth";
import Loading from "../shared/Loading";
import removeHeaderFooter from "../utils/removeHeaderFooter";
const CallbackPage = () => {
  useEffect(() => {
    removeHeaderFooter();
    handleCallback()
      .then(() => {
        window.location.href = "/"; // Redirect to home after successful login
      })
      .catch((error) => {
        console.error("Error handling callback", error);
      });
  }, []);

  return <Loading />;
};

export default CallbackPage;
