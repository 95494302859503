export default () => {
  const removeElements = () => {
    const header = document.querySelector("header");
    const footer = document.querySelector("footer");
    
    if (header) header.remove();
    if (footer) footer.remove();
  };

  // Execute when the DOM is fully loaded
  if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", removeElements);
  } else {
    removeElements();
  }
};
